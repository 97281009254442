@font-face {
  font-family: "Voor";
  src: url("../fonts/VoorReg.otf") format("truetype");
}
@font-face {
  font-family: "Nexa-ExtraLight";
  src: url("../fonts/Nexa-ExtraLight.ttf") format("truetype");
}
@font-face {
  font-family: "Nexa-Heavy";
  src: url("../fonts/Nexa-Heavy.ttf") format("truetype");
}
@font-face {
  font-family: "Nexa-Bold";
  src: url("../fonts/Nexa-Bold.otf") format("truetype");
}
/* Default styles for 1920px */

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  background-color: black;
  color: white;
  position: relative;
  font-family: "Nexa-Heavy";
}

.menu {
  display: flex;
  gap: 50px;
}

.menu-item {
  position: relative;
}

.actions {
  padding: 0 60px 0 0;
  display: flex;
  gap: 30px;
}

.menu a {
  display: inline-block;
  position: relative;
  color: #fff;
  text-decoration: none;
  padding: 5px 0px;
  transition: background-color 0.3s;
  text-transform: uppercase;
  font-weight: 700;
}

.actions a {
  display: inline-block;
  position: relative;
  color: #fff;
  transition: background-color 0.3s;
}

.actions a img {
  height: 30px;
}

.menu a:hover {
  height: 20px;
  border-bottom: 2px solid #fff;
}

/* Dropdown for SHOP+ */
.dropdown {
  position: absolute;
  top: 100%;
  left: 0;
  height: 500px;
  width: 100vw;
  background-color: #333;
  border: 1px solid #555;
  padding: 0;
  box-sizing: border-box;
  z-index: 2;
  display: grid;
  grid-template-columns: 20% 79%;
  gap: 1%;
  background-color: #cdf056;
}

.dropdown .menu1 {
  display: flex;
  flex-direction: column;
  background-color: black;
  gap: 20px;
  padding: 10%;
}
.dropdown .menu1 a {
  text-decoration: none;
}

.dropdown .menu1 a h1 {
  padding: 0;
  margin: 0;
  color: white;
  font-size: 1.5vw;
  font-family: "Voor";
  transition: background-color 0.3s;
}
.dropdown .menu1 a h1:hover {
  color: #cdf056;
}

.dropdown .menuItems {
  display: flex;
  flex-direction: column;
  background-color: black;
  padding: 3%;
}

.dropdown .menuItems .items {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  gap: 2%;
}

.dropdown .menuItems .items .item {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.dropdown .menuItems .items .item {
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  align-items: center;
}

.dropdown .menuItems .items .item .image_div {
  display: flex;
  width: 80%;
  aspect-ratio: 1/1;
  background-color: #cdf056;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
}

.dropdown .menuItems .items .item .image_div img {
  height: auto;
  width: 60%;
}
.dropdown .menuItems .items .item h2 {
  margin: 0;
  font-size: 1.2vw;
  font-family: "Nexa-Heavy";
}

/* Mobile menu */
.dropdown-menu {
  position: absolute;
  top: 60px;
  left: 0;
  right: 0;
  background-color: #333;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px 0;
  z-index: 1000;
}

.dropdown-menu a {
  padding: 10px 0;
  width: 100%;
  text-align: center;
  color: #fff;
  text-decoration: none;
  transition: background-color 0.3s;
}

.dropdown-menu a:hover {
  background-color: #555;
}

.logo img {
  height: 5%;
}

.logo a {
  text-decoration: none;
}

.logo p {
  font-size: 1.6rem;
  color: #fff;
  transition: background-color 0.3s;
}

.toggle-icon {
  display: none;
  flex-direction: column;
  cursor: pointer;
  gap: 5px;
}

.toggle-icon .bar {
  width: 25px;
  height: 3px;
  background-color: #fff;
  transition: 0.3s;
}

/* Styles for 1440px */
@media (max-width: 1440px) {
  .logo img {
    height: 45px;
  }
  .menu,
  .actions {
    gap: 0;
  }
  .menu a,
  .actions a {
    padding: 8px 14px;
  }

  .navbar {
    padding: 8px 18px;
  }
}

/* Styles for 1080px */
@media (max-width: 1080px) {
  .logo img {
    height: 35px;
  }
  .menu,
  .actions {
    display: none;
  }

  .toggle-icon {
    display: flex;
  }
}

/* Styles for 768px */
@media (max-width: 768px) {
  .toggle-icon {
    display: flex;
  }
}

/* Styles for 480px */
@media (max-width: 480px) {
  .toggle-icon {
    display: flex;
  }
}

/* Styles for 320px */
@media (max-width: 320px) {
  .toggle-icon .bar {
    width: 20px;
  }

  .logo img {
    height: 25px;
  }

  .navbar {
    padding: 4px 10px;
  }
}

.firstpage {
  display: grid;
  grid-template-columns: 45% 45%;
  justify-content: space-between;
  align-items: center;
  padding: 5%;
  margin: 0 auto;
  max-height: 100vh;
  height: 75vh;
}

.firstpage .left_part {
  flex: 1;
}

.firstpage .left_part h1 {
  font-size: 5vw;
  font-weight: 400;
  margin: 0;
  font-family: "Voor";
}

.firstpage .left_part p {
  font-size: 1.2vw;
  font-family: "Nexa-ExtraLight";
}

.firstpage .left_part button {
  background-color: #0122fb;
  color: #fff;
  border: none;
  cursor: pointer;
  border: 5px solid black;
  border-radius: 50px;
  font-family: "Nexa-Heavy";
  font-size: 1.2vw;
  height: auto;
  width: 30%;
  padding: 3% 0;
}
/* 
.right_part {
  display: grid;
  grid-template-columns: 30% 40% 30%;
  justify-content: center;
  align-items: flex-end;
  height: 80%;
}

.right_part .stars-left {
  display: flex;
  flex-direction: column;
  height: 100%;
  align-items: end;
  justify-content: space-between;
}

.right_part .stars-right {
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: baseline;
  justify-content: flex-start;
}

.right_part .stars-left .smallstar {
  width: 20%;
  margin-bottom: 10px;
}

.right_part .stars-right .mediumstar {
  width: 40%;
  height: auto;
  margin: 70px 0 0 15px;
}

.right_part .stars-left .bigstar {
  width: 80%;
  margin: 0 20px;
  animation: blink 0.8s infinite alternate;
} */
/* 
@keyframes blink {
  from {
    opacity: 1;
  }
  to {
    opacity: 0.4;
  }
}
.right_part .lion {
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  max-height: 100%;
  max-width: 100%;
}
.right_part .lion img {
  max-height: 100%;
  max-width: 100%;
  height: auto;
  width: auto;
  margin-bottom: 20px;
  padding-bottom: 0;
} */

.right_part {
  display: flex;
  position: relative;
  align-items: end;
  justify-content: center;
}

.product-image {
  max-width: 100%;
  height: auto;
}

.stars-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; /* Prevent the stars from interfering with clicks */
}

.star {
  position: absolute;
  max-width: 100%;
}

.star1 {
  top: -30%;
  left: -10%;
  width: 30%;
}

.star2 {
  top: -10%;
  right: 0%;
  width: 20%;
}

.star3 {
  bottom: 5%;
  left: 10%;
  width: 10%;
}
/* 1440px */
@media (max-width: 1440px) {
  .firstpage {
    height: 70vh;
  }
  .product-image {
    width: 60%;
    height: auto;
  }
}

/* 1024px */
@media (max-width: 1024px) {
  .firstpage {
    height: 70vh;
  }
  .product-image {
    width: 60%;
    height: auto;
  }
}

/* 768px */
@media (max-width: 768px) {
  .firstpage {
    height: 50vh;
  }
  .firstpage .left_part {
    margin-bottom: 20px;
  }
}

/* 425px */
@media (max-width: 425px) {
  .firstpage {
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    gap: 50px;
  }

  .product-image {
    width: 50%;
    height: auto;
  }

  .star1 {
    top: -30%;
    left: 0%;
    width: 25%;
  }

  .star2 {
    top: -10%;
    right: 10%;
    width: 15%;
  }

  .star3 {
    bottom: 5%;
    left: 17%;
    width: 8%;
  }

  .firstpage .left_part h1 {
    font-size: 10vw;
  }

  .firstpage .left_part p {
    font-size: 3vw;
  }

  .firstpage .left_part button {
    border: 3px solid black;
  }
}

.marquee {
  display: block;
  position: relative;
  z-index: 1;
  padding: 10px 0;
  overflow: hidden;
  background-color: var(--marquee-background-color, #cdf056);
}
.marquee__content {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.marquee__content > span {
  display: flex;
  align-items: center;
  transform: translateX(0%);
  animation: marquee var(--marquee-speed, 8s) linear infinite;
  animation-play-state: running;
  color: var(--marquee-text-color, black);
  text-transform: uppercase;
  white-space: nowrap;
  will-change: transform;
  font-size: 1.2vw;
  line-height: 1.5vw;
  padding: 0 2vw;
  font-weight: 400;
  font-family: "Nexa-Heavy";
}

@keyframes marquee {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.second-div {
  height: auto;
  background-color: black;
  display: grid;
  grid-template-columns: 20% 60% 20%;
  align-items: center;
  justify-content: center;
  padding: 2% 0;
}
.second-div .image-container1 {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: end;
}
.second-div .image-container1 .greenstar {
  width: 200px;
  height: 200px;
  object-fit: cover;

  animation: rotate 7s linear infinite;
}

.second-div .text {
  flex: 1;
  text-align: center;
}
.second-div .text h2 {
  font-size: 4vw;
  color: #cdf056;
  text-align: center;
  font-family: "Voor";
}
.second-div .image-container {
  display: flex;
  position: relative;
  width: 200px;
  height: 200px;
  justify-content: center;
  align-items: end;
}

.second-div .image-container .background-image {
  width: 200px;
  height: 200px;
  object-fit: cover;

  animation: rotate 7s linear infinite;
}

@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.second-div .image-container .overlay-image {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 60%;
  height: auto;
}

@media (max-width: 1440px) {
  .second-div .image-container1 .greenstar {
    width: 150px;
    height: 150px;
  }

  .second-div .image-container {
    width: 150px;
    height: 150px;
  }
  .second-div .image-container .background-image {
    width: 150px;
    height: 150px;
  }
}

@media (max-width: 1024px) {
  .second-div .image-container1 .greenstar {
    width: 120px;
    height: 120px;
  }

  .second-div .image-container {
    width: 120px;
    height: 120px;
  }
  .second-div .image-container .background-image {
    width: 120px;
    height: 120px;
  }
}

@media (max-width: 768px) {
  .second-div .image-container1 .greenstar {
    width: 80px;
    height: 80px;
  }

  .second-div .image-container {
    width: 80px;
    height: 80px;
  }
  .second-div .image-container .background-image {
    width: 80px;
    height: 80px;
  }
}

@media (max-width: 425px) {
  .second-div {
    padding: 5vw 0;
    display: flex;
    flex-direction: column;
    gap: 1vw;
  }
  .second-div .image-container1 {
    justify-content: center;
  }

  .second-div .text h2 {
    font-size: 6vw;
  }
}
.third_page {
  height: auto;
  position: relative;
}

.third_page .heading {
  display: grid;
  grid-template-columns: 33% 33% 33%;

  padding: 3rem 5rem;
  height: 150px;
}

.third_page .heading .title {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
}
.third_page .heading .title h2 {
  font-size: 3vw;
  font-family: "Nexa-Bold";
  font-weight: 600;
  margin: 0;
}
.third_page .heading .image {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  align-items: center;
}
.third_page .heading .image .img1,
.third_page .heading .image .img2 {
  display: flex;
  align-items: center;
  justify-content: center;
}
.third_page .bottom .image .img1 img,
.third_page .heading .image .img1 img {
  width: 100%;
}
.third_page .heading .image .img2 img,
.third_page .bottom .image .img2 img {
  width: 20%;
}
.third_page .products {
  display: block;
  position: relative;
  z-index: 1;
  overflow: hidden;
}

.third_page .products .moving_items {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: max-content;
  animation: scroll-left 20s linear infinite;
  gap: 20px;
}
.third_page .products:hover .moving_items {
  animation-play-state: paused;
}
.third_page .products .moving_items span {
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
}

/* Keyframes for scrolling */
@keyframes scroll-left {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(-50%);
  }
}

.third_page .products .moving_items span .product {
  display: flex;
  flex-direction: column;

  width: 300px;
  aspect-ratio: 2/3;
  padding: 40px 50px;
  border: 8px solid black;
  border-radius: 50px;
  align-items: center;
  justify-content: center;
  gap: 20px;
}
.third_page .products .moving_items .product img {
  width: 80%;
  height: auto;
}

.third_page .products .moving_items .product .title {
  font-size: 1vw;
  text-transform: uppercase;
  font-weight: 700;
  font-family: "Nexa-Heavy";
}

.third_page .products .moving_items .product .price {
  text-align: center;
  font-size: 1.5vw;
  font-weight: 700;
  font-family: "Nexa-Heavy";
}

.third_page .bottom {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 3rem 5rem;
}

.third_page .bottom .image {
  display: grid;
  grid-template-columns: 50% 50%;
  justify-content: center;
  align-items: center;
}
.third_page .bottom .image .img1,
.third_page .bottom .image .img2 {
  display: flex;
  align-items: center;
  justify-content: center;
}

.third_page .bggreen {
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: -1;
  padding: 0;
  margin: 0;
  width: 50%;
  height: auto;
}

@media (max-width: 1440px) {
  .third_page .heading .greenstar {
    display: none;
  }
  .third_page .heading {
    display: grid;
    grid-template-columns: 50% 50%;
    height: 100px;
    padding: 2rem 4rem;
  }
  .third_page .products .moving_items span .product {
    width: 225px;
    padding: 35px 45px;
  }
  .third_page .bottom {
    grid-template-columns: repeat(2, 1fr);
  }
  .third_page .bottom {
    padding: 2rem 4rem;
  }
}
@media (max-width: 1024px) {
  .third_page .heading {
    height: 80px;
  }
  .third_page .products .moving_items span .product {
    width: 170px;
    border: 6px solid black;
    padding: 30px 40px;
    border-radius: 40px;
  }
}

@media (max-width: 768px) {
  .third_page .products .moving_items span .product {
    width: 100px;
    gap: 15px;
    border-radius: 30px;
    padding: 25px 35px;
  }
  .third_page .heading .title h2 {
    font-size: 5vw;
  }
  .third_page .products .moving_items .product .title {
    font-size: 1.7vw;
  }

  .third_page .products .moving_items .product .price {
    font-size: 2.5vw;
  }
}

@media (max-width: 425px) {
  .third_page .heading {
    padding: 1.5rem 3rem;
    height: 60px;
  }
  .third_page .products .moving_items {
    gap: 10px;
  }

  .third_page .products .moving_items span .product {
    width: 80px;
    padding: 15px;
    border: 4px solid black;
    border-radius: 20px;
  }
}

.fourth_page {
  position: relative;
  height: auto;
  display: grid;
  grid-template-columns: 60% 40%;
}

.fourth_page .left {
  display: flex;
  flex-direction: column;
}

.fourth_page .left .moving_text {
  display: block;
  position: relative;
  z-index: 1;
  padding: 4% 0;
  overflow: hidden;
  border-top: 7px solid black;
  border-bottom: 7px solid black;
  font-family: "Voor";
  justify-content: center;
  align-items: center;
}
.fourth_page .left .moving_text .text {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  width: max-content;
  justify-content: center;
  align-items: center;
}
.fourth_page .left .moving_text .text span {
  display: inline-flex;
  align-items: center;
  transform: translateX(0%);
  animation: move 8s linear infinite;
  color: black;
  text-transform: uppercase;
  white-space: nowrap;
  will-change: transform;
  font-size: 4vw;
  padding: 0 0.8rem;
  font-weight: 400;
}

@keyframes move {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0%);
  }
}

.fourth_page .left .features {
  position: relative;
  margin: 8%;
  padding: 5%;
  border: 0.5vw solid black;
  border-radius: 5vw;
  display: flex;
  flex-direction: column;
  gap: 6vw;
}
.fourth_page .bgblue {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: -1;
  padding: 0;
  margin: 0;
  width: 60%;
  height: auto;
}

.fourth_page .left .features .feature {
  display: grid;
  grid-template-columns: 15% 60%;
  gap: 5%;
}
.fourth_page .left .features .feature img {
  width: 100%;
  height: auto;
}
.fourth_page .left .texts .title {
  font-size: 1.5vw;

  font-family: "Nexa-Heavy";
  margin: 0;
}
.fourth_page .left .texts .des {
  font-size: 1vw;
  font-family: "Nexa-ExtraLight";
}

.fourth_page .right {
  background-color: black;
  color: white;
  padding-left: 10%;
  height: auto;
}

.fourth_page .right .text {
  padding: 1vw 0;
  font-family: "Nexa-Bold";
  font-size: 1.2vw;
}

.fourth_page .right .raw-Silajit {
  background-color: #cdf056;
  width: 100%;
  aspect-ratio: 1/1.5;
}

@media (max-width: 425px) {
  .fourth_page {
    display: block;
  }
  .fourth_page .left .features .feature {
    grid-template-columns: 20% 75%;
    gap: 5%;
  }
  .fourth_page .left .texts .title {
    font-size: 3.5vw;
  }
  .fourth_page .left .texts .des {
    font-size: 2vw;
  }
  .fourth_page .right .text {
    font-size: 3vw;
  }
}

.fifth_page {
  padding-top: 8vw;
  background-color: black;
}

.fifth_page h1 {
  text-align: center;
  color: #cdf056;
  font-size: 5vw;
  font-weight: 400;
  font-family: "Voor";
}
.fifth_page img {
  width: 100%;
}

.sixthpage {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  height: auto;
  overflow: hidden;
}

.gummies-left,
.gummies-right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 9.5%;
  z-index: 1;
}

.gummies-left {
  left: 0;
  margin-left: 2%;
}

.gummies-right {
  right: 0;
  margin-right: 2%;
}

.animated-gummies img {
  animation: scrollUp 10s linear infinite;
}

@keyframes scrollUp {
  0% {
    transform: translateY(0%);
  }
  100% {
    transform: translateY(-100%);
  }
}

.content-center {
  text-align: center;
  margin: 0;
  background-color: black;
  z-index: 0;
  width: 100%;
}

.up {
  position: relative;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  margin: 0;
  padding: 0;
}

.up img {
  width: 100%;
  height: auto;
  display: block;
}

.text-overlay {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: black;
  text-align: center;
  width: 100%;
  box-sizing: border-box;
}

.text-overlay h1 {
  font-family: "Voor";
  font-size: 5vw;
  font-weight: 300;
  padding: 0;
  margin: 0;
}

.text-overlay p {
  font-size: 1.6vw;

  font-family: "Nexa-Bold";
  padding: 0;
  margin: 0;
}

.sixthpage .down {
  display: flex;
  background-color: black;
  color: white;
  align-items: center;
  justify-content: center;
  z-index: 0;
  margin: 0;
}

.sixthpage .down .features2 {
  width: 60%;
  height: auto;
  background-color: black;
  color: white;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2%;
  margin: 5% 0;
}

.sixthpage .down .features2 .feature {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2%;
  margin: 0;
  padding: 0;
  margin: 3%;
}

.sixthpage .down .features2 .feature .image_div {
  width: 40%;
  height: auto;
}

.sixthpage .down .features2 .feature .image_div img {
  width: 100%;
  height: auto;
}

.sixthpage .down .features2 .feature h2 {
  font-size: 1.2vw;
  font-family: "Nexa-Bold";
  padding: 0;
  margin: 0;
}

.sixthpage .down .features2 .feature p {
  font-size: 0.8vw;
  font-family: "Nexa-Extralight";
  text-align: center;
  padding: 0;
  margin: 0;
}
@media (max-width: 425px) {
  .sixthpage .down .features2 {
    grid-template-columns: repeat(2, 1fr);
  }
  .sixthpage .down .features2 .feature h2 {
    font-size: 2vw;
  }

  .sixthpage .down .features2 .feature p {
    font-size: 1.5vw;
  }
  .gummies-left,
  .gummies-right {

    width: 15%;

  }
}
.seventhpage {
  display: flex;
  padding: 5%;
  justify-content: center;
  align-items: center;
}
.seventhpage .box {
  display: flex;
  flex-direction: column;
  border: 5px solid black;
  height: auto;
  width: 100%;
  max-width: 1440px;
  padding: 5% 5% 10% 5%;
  border-radius: 50px;
}

.seventhpage .box .up {
  display: flex;
  flex-direction: column;
  text-align: center;
  gap: 1%;
  margin-bottom: 5%;
}

.seventhpage .box .up h1 {
  font-family: "Voor";
  font-size: 3.5vw;
  padding: 0;
  margin: 0;
}

.seventhpage .box .up p {
  padding: 0;
  margin: 0;
  font-size: 1vw;
}

.seventhpage .box .down {
  display: grid;
  grid-template-columns: 50% 50%;

}
.seventhpage .box .down span {
  display: flex;
  font-size: 1.5vw;
  text-align: center;
  gap: 2vw;
  
}
.seventhpage .box .down span h2 {
  margin: 0;
  font-size: 1.5vw;
  text-decoration: 0.15vw line-through #cdf056;

}
.seventhpage .box .down .right,
.seventhpage .box .down .left {
  display: flex;
  flex-direction: column;
  padding-left: 15%;
  gap: 2vw;
  font-family: "Nexa-Heavy";
}

footer .suscribeTitle {
  display: flex;
  background-color: black;
  justify-content: center;
  align-items: center;
}
footer .suscribeTitle h1 {
  font-family: "Voor";
  color: #cdf056;
  font-size: 4vw;
}

footer .email-Section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 7%;
  width: 86%;

  gap: 4vw;
}
footer .email-Section h2 {
  font-family: "Voor";
  margin: 0;
  font-size: 2.5vw;
}
footer .email-Section .email {
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
}

footer .email-Section .email input {
  height: 5vw;
  font-size: 2vw;
  border: 1.5px solid black;
  width: 50vw;
  padding: 0 0 0 4vw;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  font-family: "Nexa-Extralight";
}
footer .email-Section .email button {
  font-size: 2vw;
  border: 1.5px solid black;
  width: 20vw;
  padding: 0;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
}

footer .info {
  color: white;
  background-color: black;
  padding: 7vw 7vw 5vw 7vw;
  display: grid;
  grid-template-columns: 60% 15% 10% 15%;
}

footer .info .media {
  display: flex;
  flex-direction: column;
  gap: 4vw;
}

footer .info .media .logo {
  padding: 0;
  width: 40%;
}
footer .info .media .logo img {
  padding: 0;
  margin: 0;
  width: 100%;
  height: auto;
}

footer .info .media .socialmedia {
  display: flex;
  gap: 2vw;
}

footer .info .media .socialmedia img {
  height: 2vw;
  width: auto;
}

footer .info .media .textinfo {
  width: 55%;
  padding: 2vw;
  border: 2px solid white;
  border-radius: 3vw;
}

footer .info .media .textinfo p {
  padding: 0;
  margin: 0;
  font-size: 1.1vw;
  font-family: "Nexa-Bold";
}

footer .info .myAccount,
footer .info .learn,
footer .info .gethelp {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 4vw;
}

footer .info .myAccount h1,
footer .info .learn h1,
footer .info .gethelp h1 {
  padding: 0;
  margin: 0;
  font-size: 1.3vw;
  font-family: "Nexa-Heavy";
}

footer .info .myAccount ul,
footer .info .learn ul,
footer .info .gethelp ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5vw;
  font-size: 1rem;
  text-decoration: none;
}

footer .info .myAccount ul li,
footer .info .learn ul li,
footer .info .gethelp ul li {
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 1.5vw;
  font-size: 1vw;
  text-decoration: none;
  font-family: "Nexa-ExtraLight";
}

footer .trademark {
  height: auto;
  padding-left: 7vw;
}
footer .trademark p {
  font-size: 1vw;
}

@media (max-width: 425px) {
  /* footer .info {
    grid-template-columns: 33% 33% 33%
  }

  footer .info .media {
    
  } */
}
